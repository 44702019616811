import * as htmx from "htmx.org";
import type { HTMXConfigRequestEvent } from "./htmx";
import { getCookie } from "./util";

// https://github.com/bigskysoftware/htmx/issues/1690
Object.assign(window, { htmx });
import("htmx.org/dist/ext/loading-states");

htmx.config.historyCacheSize = 0;

// https://github.com/bigskysoftware/htmx/issues/1118#issuecomment-1656897840
function cancelLoadingStates() {
  document.body.querySelectorAll("[data-loading-disable][disabled]").forEach((t) => {
    // eslint-disable-next-line no-param-reassign
    (t as HTMLButtonElement).disabled = false;
  });
}
document.body.addEventListener("htmx:timeout", cancelLoadingStates);
document.body.addEventListener("htmx:sendAbort", cancelLoadingStates);
document.body.addEventListener("htmx:sendError", cancelLoadingStates);

// Add headers automatically to all outbound requests (to be processed by middleware).
document.addEventListener("htmx:configRequest", (ev) => {
  const event = ev as HTMXConfigRequestEvent;
  event.detail.headers["X-CSRFToken"] = getCookie("rind_csrftoken");
  event.detail.headers["X-Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
});
