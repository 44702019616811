import Alpine from "alpinejs";
import { ComponentBase, HydratedComponent } from "..";
import { hydrateComponent } from "../util";

interface Args {
  accordionId: number;
}

interface Props {
  activeId?: number | null;
  accordionId: number;
  expanded: boolean;
}

const accordionItem: HydratedComponent<Args, Props> = ({ accordionId }) => ({
  ...({} as ComponentBase),
  accordionId,
  get expanded(): boolean {
    return this.activeId === this.accordionId;
  },
  set expanded(value: boolean) {
    this.activeId = value ? this.accordionId : null;
  },
});

Alpine.data("accordionItem", hydrateComponent(accordionItem));
