/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { AlpineWebComponent, Attribute, Attributes, html } from "../alpineWebComponent";

const template = html`
  <div :class="classes">
    <form x-bind="formAttrs">
      <input type="hidden" :value="title" name="title" />
      <input type="hidden" :value="toDoId" name="to-do-id" />
      <div class="flex-1">
        <template x-if="title">
          <h5 class="mb-2" x-text="title" is="headline"></h5>
        </template>
        <template x-if="description">
          <p class="leading-tight mb-4" x-text="description"></p>
        </template>
      </div>
      <template x-if="buttonLabel">
        <div>
          <button x-bind="buttonAttrs">
            <span x-text="buttonLabel" class="uppercase leading-none"></span>
          </button>
        </div>
      </template>
    </form>
  </div>
`;

const attrs = {
  title: Attribute.String(""),
  description: Attribute.String(""),
  "button-label": Attribute.String(),
  "to-do-id": Attribute.String(),
  "form-url": Attribute.String(),
  "x-class": Attribute.String(),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  classes(): string;
  buttonAttrs: () => Record<string, unknown>;
}

export class ToDoCard extends AlpineWebComponent<State, Attrs>(
  "x-to-do-card",
  attrs,
  template
) {
  data() {
    return {
      classes: () => {
        let cls =
          "border-solid border border-l-8 border-ext-light-gray rounded-r-lg border-l-p-teal p-6 shadow-md block sm:flex sm:flex-col";
        if (this.state["x-class"]) cls += ` ${this.state["x-class"]}`;
        return cls;
      },
      buttonAttrs: () => ({
        id: `to-do-${this.state["to-do-id"]}`,
        href: "#",
        class: "x-button sm:!w-auto !py-2 sm:!px-10 !h-auto",
        type: "submit",
      }),
      formAttrs: () => ({
        "hx-get": this.state["form-url"],
        "hx-trigger": "submit",
        class: "sm:flex sm:flex-col flex-1",
      }),
      buttonLabel: () => this.state["button-label"],
      toDoId: () => this.state["to-do-id"],
    };
  }
}

ToDoCard.define();

export default ToDoCard;
