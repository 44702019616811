import Alpine from "alpinejs";

interface DropDown {
  open: boolean;
  toggle(): void;
  close(): void;
}

function dropdown(this: DropDown) {
  return {
    open: false,
    toggle() {
      if (this.open) {
        this.close();
      }

      this.open = true;
    },
    close(focusAfter?: HTMLElement) {
      if (!this.open) return;

      this.open = false;

      if (focusAfter) {
        focusAfter.focus();
      }
    },
  };
}

Alpine.data("dropdown", dropdown);
