import AlpineInstance from "alpinejs";

import { ComponentBase, HydratedComponent } from "..";
import { hydrateComponent } from "../util";

interface Args {}

interface Props {
  show: boolean;
}

const alert: HydratedComponent<Args, Props> = () => ({
  ...({} as ComponentBase),
  // We init the "show" variable as true so the alerts that are
  // not timed are not affected by this logic
  show: true,
  init() {
    const timedAlertTemplate = this.$el as HTMLTemplateElement;
    const timedAlert = timedAlertTemplate.content.querySelector(
      '[data-alert-type="timed"]'
    );
    if (timedAlert && timedAlert.hasAttribute("data-show-from-time")) {
      // We hide the alerts that have the show-from-time attribute
      this.show = false;
      // Get the time from the backend and run an interval to check
      // if it is time to show the alert
      const showFromTime = timedAlert.getAttribute("data-show-from-time");
      if (showFromTime) {
        const showFromTimeDate = new Date(showFromTime);
        if (new Date() > showFromTimeDate) {
          this.show = true;
        } else {
          const interval = setInterval(() => {
            if (new Date() > showFromTimeDate) {
              this.show = true;
              clearInterval(interval);
            }
          }, 30000);
        }
      }
    }
  },
});

AlpineInstance.data("alert", hydrateComponent(alert));
