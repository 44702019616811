import { AlpineWebComponent, html } from "../alpineWebComponent";
import type { HTMXAfterRequestEvent } from "../../htmx";

const template = html`
  <span x-bind="attrs">
    <slot></slot>
    <span x-cloak x-show="failure">
      <slot name="failure"></slot>
    </span>
    <span x-cloak x-show="initial">
      <slot name="initial"></slot>
    </span>
    <span x-cloak x-show="success">
      <slot name="success"></slot>
    </span>
  </span>
`;

export const enum RequestStatus {
  FAILURE = "failure",
  INITIAL = "initial",
  SUCCESS = "success",
}

interface State {
  attrs: Record<string, unknown>;
  requestStatus: RequestStatus;
  failure: () => boolean;
  initial: () => boolean;
  success: () => boolean;
}

export class HtmxRequest extends AlpineWebComponent<State>("x-htmx-request", {}, template) {
  data() {
    return {
      attrs: {
        "@htmx:afterRequest": (ev: HTMXAfterRequestEvent) => {
          this.state.requestStatus = ev.detail.successful
            ? RequestStatus.SUCCESS
            : RequestStatus.FAILURE;
        },
        // NOTE: This is not a standard HTMX event and serves as a way to reset the request state for this component.
        "@htmx:initialize": () => {
          this.state.requestStatus = RequestStatus.INITIAL;
        },
        "@htmx:responseError": () => {
          this.state.requestStatus = RequestStatus.FAILURE;
        },
      },
      requestStatus: RequestStatus.INITIAL,
      failure: () => this.state.requestStatus === RequestStatus.FAILURE,
      initial: () => this.state.requestStatus === RequestStatus.INITIAL,
      success: () => this.state.requestStatus === RequestStatus.SUCCESS,
    };
  }
}

HtmxRequest.define();

export default HtmxRequest;
