export class Deferred<T> implements Promise<T> {
  private resolveSelf: (value: T | PromiseLike<T>) => void;

  private rejectSelf: (reason?: unknown) => void;

  private promise: Promise<T>;

  constructor() {
    this.resolveSelf = () => undefined;
    this.rejectSelf = () => undefined;
    this.promise = new Promise((resolve, reject) => {
      this.resolveSelf = resolve;
      this.rejectSelf = reject;
    });
  }

  public then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | undefined | null,
    onrejected?: ((reason: unknown) => TResult2 | PromiseLike<TResult2>) | undefined | null
  ): Promise<TResult1 | TResult2> {
    return this.promise.then(onfulfilled, onrejected);
  }

  public catch<TResult = never>(
    onrejected?: ((reason: unknown) => TResult | PromiseLike<TResult>) | undefined | null
  ): Promise<T | TResult> {
    return this.promise.catch(onrejected);
  }

  public finally(onfinally: (() => void) | null | undefined) {
    return this.promise.finally(onfinally);
  }

  public resolve(val: T) {
    this.resolveSelf(val);
  }

  public reject(reason: unknown) {
    this.rejectSelf(reason);
  }

  [Symbol.toStringTag] = "Promise";
}

export default Deferred;
