/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  loadStripe,
  Stripe,
  StripeCardNumberElement,
  StripeCardNumberElementOptions,
  StripeElement,
  StripeElements,
  StripeError,
} from "@stripe/stripe-js";
import AlpineInstance from "alpinejs";
import { ComponentBase, HydratedComponent } from "..";
import { hydrateComponent } from "../util";

interface Args {
  stripePublicKey: string;
  activePriceId: string;
}

interface Props {
  errorMessage: string;
  disableInputs: boolean;
  cardElements: Record<string, StripeElement>;
  paymentIntentSecret: string | undefined;
  setupIntentSecret: string | undefined;
  elements?: StripeElements;
  stripe?: Stripe | null;
  stripePublicKey: string;
  error: boolean;
  loading: boolean;
  subscribing: boolean;
  activePriceId: string;
  promoCode: string;
  activePrice: string;
  applyPromoCode(promoCode: string): void;
  init: () => Promise<void>;
  subscribe: () => Promise<void>;
  syncCustomer: (paymentMethodId?: string) => Promise<void>;
  updateSecrets: () => void;
  clearInputs: () => void;
  subscribeInit: () => void;
  toggleInputs: () => void;
  reInitForm: () => void;
  showError: (message?: string) => void;
  handleSubError: () => void;
}

const paymentBox: HydratedComponent<Args, Props> = ({
  stripePublicKey,
  activePriceId,
}) => ({
  ...({} as ComponentBase),
  subscribing: false,
  stripePublicKey,
  cardElements: {},
  errorMessage: "",
  disableInputs: false,
  error: false,
  loading: false,
  paymentIntentSecret: "",
  setupIntentSecret: "",
  paymentMethodId: "",
  activePriceId,
  promoCode: "",
  activePrice: "",
  async init() {
    this.stripe = await loadStripe(this.stripePublicKey);
    if (!this.stripe) throw new Error("failed to load stripe API");
    this.elements = this.stripe?.elements();
    const cardNumOptions: StripeCardNumberElementOptions = {
      showIcon: true,
    };
    this.cardElements = {
      "#card-number": this.elements?.create("cardNumber", cardNumOptions),
      "#card-expiry": this.elements?.create("cardExpiry"),
      "#card-cvc": this.elements?.create("cardCvc"),
    };

    Object.entries(this.cardElements).forEach(([id, element]) => {
      element.mount(id);
      // @ts-expect-error // element.on() has conflicting signatures
      element.on("change", ({ error }: { error: StripeError }) => {
        const displayError = document.querySelector(`${id}-error`) as HTMLParagraphElement;
        const inputWrapper = document.querySelector(id) as HTMLElement;

        if (error) {
          displayError.textContent = error.message ? error.message : "";
          inputWrapper.classList.add("error");
        } else {
          displayError.textContent = "";
          inputWrapper.classList.remove("error");
        }
      });
    });
    this.$dispatch("activate-price");
  },
  handleSubError() {
    this.$dispatch("update-state", { state: "failure" });
    this.$dispatch("failure-message", {
      message:
        "We're investigating why this happened! Email us at membersupport@joinzest.com if you need help.",
    });
  },
  toggleInputs() {
    this.disableInputs = !this.disableInputs;
    (document.getElementById("zip") as HTMLInputElement).disabled = this.disableInputs;
    Object.values(this.cardElements).forEach((element) => {
      element.update({ disabled: this.disableInputs });
    });
  },
  clearInputs() {
    (document.getElementById("zip") as HTMLInputElement).value = "";
    Object.values(this.cardElements).forEach((element) => {
      element.update({ value: "" });
    });
  },
  applyPromoCode(promoCode = "") {
    this.promoCode = promoCode;
    setTimeout(() => {
      this.$dispatch("activate-price");
      this.$dispatch("dismiss", { id: "promocode" });
    }, 100);
  },
  setActivePrice(activePrice = "") {
    this.activePrice = activePrice;
  },
  showError(message?: string) {
    this.error = true;
    if (message) {
      this.errorMessage = message;
    } else {
      this.errorMessage = "Failed to update card.";
    }
  },
  updateSecrets() {
    this.paymentIntentSecret = document.getElementById("client-secret")?.dataset.secret;
    this.setupIntentSecret =
      document.getElementById("payment-intent-secret")?.dataset.secret;
  },
  reInitForm() {
    this.updateSecrets();
    if (this.loading) {
      this.loading = false;
      this.toggleInputs();
    }
  },
  subscribeInit() {
    this.error = false;
    this.subscribing = true;
    this.$dispatch("subscribe-init");
  },
  async subscribe() {
    if (!this.stripe || !this.elements) {
      throw new Error("Stripe was not initialized");
    }
    this.updateSecrets();
    if (this.paymentIntentSecret) {
      // Paying customer:
      // Capture the card and paying subscription.
      // Use the paymentIntentSecret to attach the card.
      const { paymentIntent, error } = await this.stripe.confirmCardPayment(
        this.paymentIntentSecret,
        {
          payment_method: {
            card: this.cardElements["#card-number"] as StripeCardNumberElement,
            billing_details: {
              name: (document.querySelector("#cardholder-name") as HTMLInputElement).value,
              address: {
                postal_code: (document.querySelector("#zip") as HTMLInputElement).value,
              },
            },
          },
        }
      );
      if (error !== undefined) {
        const msg = String(error.message);
        this.$dispatch("failure-message", { message: msg });
        this.subscribing = false;
        this.$dispatch("show-modal", { id: "failure" });
      } else {
        const paymentMethodId = (paymentIntent.payment_method as string).toString();
        await this.syncCustomer(paymentMethodId);
      }
    } else if (this.setupIntentSecret) {
      // In-network flow:
      // Capture a card, but free subscription
      // use the setupIntentSecret to attach the card.
      const { setupIntent, error } = await this.stripe.confirmCardSetup(
        this.setupIntentSecret,
        {
          payment_method: {
            card: this.cardElements["#card-number"] as StripeCardNumberElement,
            billing_details: {
              name: (document.querySelector("#cardholder-name") as HTMLInputElement).value,
              address: {
                postal_code: (document.querySelector("#zip") as HTMLInputElement).value,
              },
            },
          },
        }
      );
      if (error !== undefined) {
        const msg = String(error.message);
        this.$dispatch("failure-message", { message: msg });
        this.subscribing = false;
        this.$dispatch("show-modal", { id: "failure" });
      } else {
        const paymentMethodId = (setupIntent.payment_method as string).toString();
        await this.syncCustomer(paymentMethodId);
      }
    } else {
      // If there's no client secret or setup intent secret, then no payment is needed
      // currently, skip Stripe Elements and just sync the customer
      await this.syncCustomer();
    }
  },
  async syncCustomer(paymentMethodId = "") {
    interface CustomerRes {
      result: string;
    }
    const params = new URLSearchParams({ pm_id: paymentMethodId });
    const res = await fetch(`/payment/sync-customer/?${params.toString()}`);
    const json = (await res.json()) as CustomerRes;
    if (json.result.toUpperCase() !== "SUCCESS") {
      // eslint-disable-next-line no-console
      console.debug("ERROR: Customer sync failed");
      this.$dispatch("failure-message", {
        message:
          "We're investigating why this happened! Email us at membersupport@joinzest.com if you need help.",
      });
      this.subscribing = false;
      this.$dispatch("show-modal", { id: "failure" });
    }
    this.subscribing = false;
    this.$dispatch("subscribe-success");
  },
});

AlpineInstance.data("paymentBox", hydrateComponent(paymentBox));
