import { AlpineWebComponent, Attribute, html } from "../alpineWebComponent";

const template = html`
  <div class="app-text-eyebrow text-p-navy">
    <x-icon name="account" class="w-3 h-3 mr-2"></x-icon>
    <span x-text="$data['patient-name']"></span>
  </div>
`;

const attrs = {
  "patient-name": Attribute.String(""),
};

export const PatientName = AlpineWebComponent("x-patient-name", attrs, template);

PatientName.define();

export default PatientName;
