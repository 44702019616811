import AWC, { Attribute, html } from "../alpineWebComponent";

const attrs = {
  fit: Attribute.Boolean(),
  no: Attribute.String(),
  other: Attribute.String(),
  title: Attribute.String(),
  yes: Attribute.String(),
};

const template = html`
  <div class="my-9 text-center">
    <slot name="title">
      <template x-if="title">
        <h3 is="headline" class="mb-4" x-text="title"></h3>
      </template>
    </slot>
    <slot></slot>
    <div class="flex flex-wrap-reverse justify-center gap-4 mt-9">
      <slot name="actions">
        <template x-if="no">
          <button
            class="x-button prompt-no"
            @click="$dispatch('no')"
            :fit="fit"
            :id="$id('no')"
            secondary
            x-text="no"
          ></button>
        </template>
        <template x-if="other">
          <button
            class="x-button prompt-other"
            @click="$dispatch('other')"
            :fit="fit"
            :id="$id('other')"
            secondary
            x-text="other"
          ></button>
        </template>
        <template x-if="yes">
          <button
            class="x-button prompt-yes"
            @click="$dispatch('yes')"
            :fit="fit"
            :id="$id('yes')"
            x-text="yes"
          ></button>
        </template>
      </slot>
    </div>
  </div>
`;

/**
 * A container for yes/no/other style prompts, ideal for including in a Modal component.
 *
 * Yes/no/other buttons are enabled by providing labels for them. When clicked, they each
 * emit a `yes`/`no`/`other` CustomEvent. These events bubble and can be caught anywhere in
 * the Prompt's subtree, including by the Prompt component itself.
 *
 * Example:
 * ```html
 * <div x-data="doomsdayDevice" x-on:yes="doBadThings(localStorage)">
 *   <x-modal open>
 *     <x-prompt title="Are you sure?" yes="Yes" no="No" x-on:no="$dispatch('dismiss')">
 *       <p>This may or may not have dire consequences.</p>
 *     </x-prompt>
 *   </x-modal>
 * </div>
 * ```
 *
 * A string label must be provided for each of the yes/no/other buttons in order for them
 * to be visible, otherwise the prompt will have no actions at all. This can be used
 * to create custom actions with a slot override:
 *
 * ```html
 * <x-prompt title="Pick a path">
 *  <button class="x-button" link x-on:click="alert('up!')" slot="actions">Up</button>
 *  <button class="x-button" link x-on:click="alert('down!')" slot="actions">Down</button>
 * </x-prompt>
 * ```
 *
 * Slots:
 *   - `default`: Descriptive text for the prompt.
 *   - `title`: Replaces the title markup for custom layouts.
 *   - `actions`: Replaces all three buttons for custom action layouts.
 */
export class Prompt extends AWC("x-prompt", attrs, template) {}

Prompt.define();

export default Prompt;
