import Alpine from "alpinejs";
import { DateTime } from "luxon";
import { Component, ComponentBase } from "..";

interface Props {
  selectedDate: number | null;
  selectedDateString: string | null;
  selectedTime: string | null;
  selectTimeslot: (
    unixTimeStamp: number,
    time: string,
    inNetwork: boolean | null,
    mode: string
  ) => void;
  parse_slot_title: (isoDate: string) => string;
  confirmAppt: () => void;
  raiseConflict: () => void;
}

const schedule: Component<Props> = () => ({
  ...({} as ComponentBase),
  selectedDate: null,
  selectedDateString: null,
  selectedTime: null,

  selectTimeslot(
    unixTimeStamp: number,
    time: string,
    inNetwork: boolean | null,
    mode: string
  ) {
    const locale =
      navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
    const date = new Date(unixTimeStamp * 1000);

    this.selectedDate = unixTimeStamp;
    this.selectedDateString = date.toLocaleDateString(locale, {
      month: "short",
      weekday: "long",
      day: "numeric",
      year: "numeric",
    });
    this.selectedTime = time;

    if (inNetwork && mode !== "NEW_MEMBER_VISIT") {
      this.$dispatch("show-modal", { id: "schedule-copay" });
    } else {
      this.$dispatch("show-modal", { id: "schedule-confirm" });
    }
  },

  parse_slot_title(isoDate: string) {
    const dateObject = DateTime.fromISO(isoDate);
    const noBreakHyphenDoc = new DOMParser().parseFromString("&#8209;", "text/html");
    const noBreakHyphen = noBreakHyphenDoc.documentElement.textContent
      ? noBreakHyphenDoc.documentElement.textContent
      : "-";
    return dateObject.toFormat("h:mm a ZZZZ").replace("-", noBreakHyphen);
  },

  confirmAppt() {
    this.$dispatch("dismiss", { id: "schedule-confirm" });
    this.$dispatch("show-modal", { id: "schedule-success" });
  },

  raiseConflict() {
    this.$dispatch("dismiss", { id: "schedule-confirm" });
    this.$dispatch("show-modal", { id: "schedule-conflict" });
  },
});

Alpine.data("schedule", schedule);
