import * as Sentry from "@sentry/browser";
import { initialize } from "@iterable/web-sdk/dist/authorization";
import { track } from "@iterable/web-sdk/dist/events";
import Deferred from "../deferred";
import { getCookie } from "../util";

export async function fetchIterableToken(payload: { userID?: string }): Promise<string> {
  if (payload.userID === undefined) {
    throw new Error("Anonymous login not permitted");
  }

  // To cut down on network traffic the backend may provide a JWT ahead of time in the page
  // header. This can only be used once since it may expire or be invalidated by the SDK
  // for other reasons, in which case this function will be invoked again to fetch the token
  // from the backend.
  if (window.$$ITERABLE_TOKEN) {
    const token = window.$$ITERABLE_TOKEN;
    window.$$ITERABLE_TOKEN = undefined;
    return token;
  }

  const formData = new FormData();
  formData.append("pk", payload.userID);

  return fetch("/lead/iterable/token/", {
    body: formData,
    headers: {
      Accept: "application/json",
      "X-CSRFToken": getCookie("rind_csrftoken"),
    },
    method: "POST",
  })
    .then((res) => res.json())
    .then((p: { token: string }) => p.token);
}

export class IterableApi {
  private deferredLogin = new Deferred<void>();

  private disabled = false;

  private setUserID: (uid: string) => Promise<string>;

  constructor() {
    if (!window.$$ITERABLE_KEY) {
      Sentry.captureMessage(
        "Iterable tracking disabled; no client API key provided",
        "warning"
      );
      this.disabled = true;
      this.setUserID = () => Promise.resolve("");
      return;
    }

    const { setUserID } = initialize(window.$$ITERABLE_KEY, fetchIterableToken);
    this.setUserID = setUserID;

    if (window.$$LEAD_PK) {
      this.login(window.$$LEAD_PK).catch(Sentry.captureException);
    }
  }

  login = async (uid: string) => {
    if (this.disabled) return;
    await this.setUserID(uid);
    this.deferredLogin.resolve();
  };

  track = async (eventName: string, dataFields: Record<string, unknown>) => {
    if (this.disabled) return;

    await this.deferredLogin;

    try {
      let campaignId: number | undefined = parseInt(
        getCookie("iterableEmailCampaignId"),
        10
      );
      let templateId: number | undefined = parseInt(getCookie("iterableTemplateId"), 10);

      if (Number.isNaN(campaignId)) campaignId = undefined;
      if (Number.isNaN(templateId)) templateId = undefined;

      await track({
        campaignId,
        createdAt: new Date().valueOf(),
        dataFields,
        eventName,
        templateId,
      });
    } catch (err) {
      Sentry.withScope((scope) => {
        // Iterable errors should not trigger user feedback, so drop level to warning.
        scope.setLevel("warning");
        Sentry.captureException(err);
      });
    }
  };
}
