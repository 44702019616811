/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/extensions */
import Alpine from "alpinejs";
import { ComponentBase, HydratedComponent } from "..";
import { hydrateComponent } from "../util";

interface Args {
  skip: number;
}

interface Props {
  skip: number;
  atBeginning: boolean;
  atEnd: boolean;
  next: () => void;
  prev: () => void;
  to: (strategy: (current: number, offset: number) => number | undefined) => void;
  focusableWhenVisible: {
    $el?: HTMLElement;
    atBeginning?: boolean;
    atEnd?: boolean;
    "x-intersect:enter": () => void;
    "x-intersect:leave": () => void;
  };
  disableNextAndPreviousButtons: {
    $el?: HTMLElement;
    atBeginning?: boolean;
    atEnd?: boolean;
    "x-intersect:enter.threshold.05": () => void;
    "x-intersect:leave.threshold.05": () => void;
  };
}

const carousel: HydratedComponent<Args, Props> = ({ skip }) => ({
  ...({} as ComponentBase),
  skip,
  atBeginning: false,
  atEnd: false,
  next() {
    this.to((current: number, offset: number) => current + offset * this.skip);
  },
  prev() {
    this.to((current: number, offset: number) => current - offset * this.skip);
  },
  to(strategy: (current: number, offset: number) => number | undefined) {
    const { slider } = this.$refs;
    const current = slider.scrollLeft;
    let elChild = slider.firstElementChild;
    if (elChild == null) {
      throw new Error("Missing first child");
    }
    if (elChild.tagName === "TEMPLATE") {
      // @ts-expect-error // Assigning ChildNode to Element
      elChild = elChild.nextSibling;
    }
    const offset = elChild?.getBoundingClientRect().width;
    slider.scrollTo({ left: strategy(current, offset as number), behavior: "smooth" });
  },
  focusableWhenVisible: {
    "x-intersect:enter": function xIntEnter() {
      this.$el?.removeAttribute("tabindex");
    },
    "x-intersect:leave": function xIntLeave() {
      this.$el?.setAttribute("tabindex", "-1");
    },
  },
  disableNextAndPreviousButtons: {
    "x-intersect:enter.threshold.05": function xIntEnterThreshold05() {
      const children = this.$el?.parentElement?.children;
      if (children === undefined) return;
      const slideEls = Array.from(children).filter((child) => child.tagName !== "TEMPLATE");

      // If this is the first slide.
      if (slideEls[0] === this.$el) {
        this.atBeginning = true;
        // If this is the last slide.
      } else if (slideEls[slideEls.length - 1] === this.$el) {
        this.atEnd = true;
      }
    },
    "x-intersect:leave.threshold.05": function xIntLeaveThreshold05() {
      const children = this.$el?.parentElement?.children;
      if (children === undefined) return;
      const slideEls = Array.from(children).filter((child) => child.tagName !== "TEMPLATE");
      // If this is the first slide.
      if (slideEls[0] === this.$el) {
        this.atBeginning = false;
        // If this is the last slide.
      } else if (slideEls[slideEls.length - 1] === this.$el) {
        this.atEnd = false;
      }
    },
  },
});

Alpine.data("carousel", hydrateComponent(carousel));
