import * as Sentry from "@sentry/browser";
import { getCookie } from "../util";

export enum AnalyticsEngagementEvent {
  INTRO_SCHEDULED = "INTRO_SCHEDULED",
  BEGIN_TO_DO = "BEGIN_TO_DO",
}

export class RindAnalytics {
  track = async (eventName: string) => {
    try {
      const events = Object.values(AnalyticsEngagementEvent).map((v) => v.toLowerCase());
      if (events.includes(eventName as AnalyticsEngagementEvent)) {
        return fetch(`/lead/t/${eventName}/`, {
          headers: {
            "X-CSRFToken": getCookie("rind_csrftoken"),
          },
          method: "POST",
        });
      }
    } catch (err) {
      Sentry.captureException(err);
    }
    return Promise<void>;
  };
}
