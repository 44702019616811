/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { AlpineWebComponent, Attribute, Attributes, html } from "../alpineWebComponent";

const template = html`
  <div
    class="block sm:flex py-6 pt-12 sm:py-6 px-8 bg-p-navy rounded-lg sm:gap-6 relative"
    x-show="show"
    x-transition
  >
    <button
      data-testid="home-top-close-button"
      class="absolute top-4 right-4"
      @click="hide()"
    >
      <x-icon name="x" class="text-white w-6 h-6 sm:w-4 sm:h-4"></x-icon>
    </button>
    <div class="sm:w-3/5">
      <div class="sm:max-w-md">
        <h4 is="headline" class="mb-4 !text-white">
          <slot name="title"> </slot>
        </h4>
        <p is="md" class="text-white">
          <slot name="copy"> </slot>
        </p>
        <template x-if="$data['btn-href']">
          <a
            data-testid="home-top-link"
            @click="setHidden()"
            class="btn-primary mt-6 sm:max-w-sm !bg-p-teal hover:!bg-[#238B8B] focus:!ring-[#238B8B]"
            :href="$data['btn-href']"
            x-text="$data['btn-label']"
          >
          </a>
        </template>
      </div>
    </div>
    <div class="sm:w-2/5 relative mt-6 sm:mt-0">
      <template x-if="$data['img-src']">
        <img
          :alt="$data['img-alt']"
          class="mx-auto h-full w-full max-w-xs
            sm:max-w-none sm:absolute"
          :src="$data['img-src']"
        />
      </template>
    </div>
  </div>
`;

const attrs = {
  show: Attribute.Boolean(),
  "img-alt": Attribute.String(""),
  "img-src": Attribute.String(""),
  "btn-href": Attribute.String(""),
  "btn-label": Attribute.String(""),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  init: () => void;
  hide: () => void;
  setHidden: () => void;
}

export class HomeTop extends AlpineWebComponent<State, Attrs>(
  "x-home-top",
  attrs,
  template
) {
  data(): Omit<State, keyof Attrs> {
    return {
      init: () => {
        const sessionStorage = window.sessionStorage || null;
        this.state.show = sessionStorage?.getItem("homeTop")
          ? sessionStorage.getItem("homeTop") !== "false"
          : this.state.show;
      },
      hide: () => {
        window.sessionStorage?.setItem("homeTop", "false");
        this.state.show = false;
      },
      setHidden: () => {
        window.sessionStorage?.setItem("homeTop", "false");
      },
    };
  }
}

HomeTop.define();

export default HomeTop;
