/// <reference types="./globals" />

/* istanbul ignore next */
export const getCurrentPath = () => window.location.pathname;
/* istanbul ignore next */
export const getCurrentUrl = () => window.location.href;
/* istanbul ignore next */
export const getOrigin = () => window.location.origin;
/* istanbul ignore next */
export const getHostName = () => window.location.hostname;
/* istanbul ignore next */
export const getProtocol = () => window.location.protocol;

export function getDomain(href: string) {
  return href.split(".").slice(-2).join(".");
}

export function isDomainLink(href: string) {
  const url = new URL(href);

  return getDomain(url.hostname) === getDomain(getHostName());
}

export function isSameProtocol(href: string) {
  const url = new URL(href);

  return url.protocol === getProtocol();
}

export function isExternalLink(link: HTMLAnchorElement) {
  const { href } = link;

  return !(
    !href ||
    href.startsWith(getOrigin()) ||
    (isDomainLink(href) && isSameProtocol(href)) ||
    href[0] === "?" ||
    href[0] === "/" ||
    href[0] === "#" ||
    href.substring(0, 4) === "tel:" ||
    href.substring(0, 7) === "mailto:"
  );
}

export function isCurrentPage(link: HTMLAnchorElement) {
  const currentUrl = getCurrentUrl().split("#")[0];
  const currentPath = getCurrentPath();
  const activeLinkUrl = link.href.split("#")[0];
  if (activeLinkUrl === currentUrl || activeLinkUrl === currentPath) return true;
  const activePaths = link.getAttribute("data-active-paths") || "";
  const activePathsArr = activePaths.split(",");
  return activePathsArr.some((activePath) => activePath === currentPath);
}

export default function linksInit() {
  /* istanbul ignore next */
  window.htmx?.process(document.body);
  const links = document.querySelectorAll("a[data-active-paths]");
  const linksArr = Array.from(links) as HTMLAnchorElement[];

  linksArr.forEach((link) => {
    link.classList.remove("active");

    if (isExternalLink(link)) {
      // eslint-disable-next-line no-param-reassign
      link.target = "_blank";
    }

    if (isCurrentPage(link)) {
      link.classList.add("active");
    }
  });
}

/* istanbul ignore if */
if (typeof document !== "undefined") {
  document.addEventListener("htmx:load", linksInit);
  document.addEventListener("htmx:pushedIntoHistory", linksInit);
}
