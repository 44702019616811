/* eslint-disable class-methods-use-this */
import AWC, { Attributes } from "../alpineWebComponent";

const attrs = {};
type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  loadMessage: (event: { detail: { message: string } }) => void;
}

export class OpenMessage extends AWC<State, Attrs>("x-open-message", attrs) {
  data() {
    return {
      loadMessage: ($event: { detail: { message: string } }) => {
        localStorage.setItem("default_message", $event.detail.message);
        window.location.href = "/account/messages/";
      },
    };
  }
}

OpenMessage.define();

export default OpenMessage;
