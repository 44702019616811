import Alpine from "alpinejs";
import { AlpineClass, ComponentBase, HydratedComponent } from "..";
import { hydrateComponent } from "../util";

interface Data {
  disabled: boolean;
  errors: string[];
  help: string;
  readonly: boolean;
  value: string;
}

interface Props extends Data {
  containerClass: AlpineClass;
  selectClass: AlpineClass;
  labelClass: AlpineClass;
  message: string;
  messageClass: string;
}

const selectDropdown: HydratedComponent<Data, Props> = (data) => ({
  ...({} as ComponentBase),
  ...data,
  get containerClass() {
    let cls = "";
    if (this.errors.length > 0) cls += " border-sys-error";
    else if (this.readonly) cls += " border-ext-slate";
    if (this.readonly) cls += " bg-ext-light-gray";
    if (this.value === "") cls += " empty-value";
    return cls;
  },
  get selectClass() {
    let cls = "";
    if (this.errors.length > 0) cls += " placeholder-shown:text-sys-error";
    if (this.readonly) cls += " bg-ext-light-gray";
    if (this.disabled) cls += " text-ext-slate";
    return cls;
  },
  get labelClass() {
    return {
      [this.messageClass]: true,
      "opacity-0": this.value === "",
      "text-input-label": true,
    };
  },
  get message() {
    return this.errors.join("\n") || this.help;
  },
  get messageClass() {
    if (this.errors.length > 0) {
      return "text-sys-error";
    }
    return "";
  },
});

Alpine.data("selectDropdown", hydrateComponent(selectDropdown));
