/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { AlpineWebComponent, Attribute, Attributes, html } from "../alpineWebComponent";

const template = html`
  <div>
    <div :class="rootClass">
      <div class="text-p-white" x-text="caption"></div>
    </div>
    <p x-text="desc"></p>
    <code class="text-ext-slate block" x-text="hex"></code>
    <div class="prose app-prose mt-1">
      <code :class="bgClass" x-text="'.bg'+color"></code>
      <code :class="tClass" x-text="'.text'+color"></code>
    </div>
  </div>
`;

const attrs = {
  color: Attribute.String(),
  caption: Attribute.String(),
  desc: Attribute.String(),
  hex: Attribute.String(),
  invert: Attribute.Boolean(),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  rootClass: () => string;
  bgClass: () => string;
  tClass: () => string;
}

export class ColorChip extends AlpineWebComponent<State, Attrs>(
  "x-color-chip",
  attrs,
  template
) {
  data() {
    return {
      rootClass: () =>
        `rounded-md bg-${this.state.color} mb-2 w-32 h-32 border-${
          this.state.invert ? "s-gray" : "transparent"
        } border-2 grid place-items-center`,
      bgClass: () =>
        `block bg-${this.state.color} text-${
          this.state.invert ? "p-navy" : "p-white"
        } rounded-md px-2 py-1`,
      tClass: () =>
        `block bg-${this.state.invert ? "p-navy" : "p-white"} text-${
          this.state.color
        } rounded-md px-2 py-1`,
    };
  }
}

ColorChip.define();

export default ColorChip;
