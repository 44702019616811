import AWC, { Attribute, Attributes } from "../alpineWebComponent";

const attrs = {
  "event-id": Attribute.String(),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  accordionToggleClass: () => Record<string, boolean>;
  expanded: boolean;
  openChat: (message: string) => void;
  showModal: () => void;
  toggleAccordion: () => void;
}

export class Appointment extends AWC<State, Attrs>("x-appointment", attrs) {
  data() {
    return {
      accordionToggleClass: () => ({ "rotate-180": this.state.expanded }),
      expanded: false,
      openChat: (message: string) => {
        this.$dispatch("load-message", { message });
      },
      showModal: () => {
        this.$dispatch("load-appointment-modal", this.state["event-id"]);
      },
      toggleAccordion: () => {
        this.state.expanded = !this.state.expanded;
      },
    };
  }
}

Appointment.define();

export default Appointment;
