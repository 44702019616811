/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import Alpine from "alpinejs";
import { ComponentBase, HydratedComponent } from "..";
import { hydrateComponent } from "../util";

export interface Pharmacy {
  name: string;
  address: string;
  id?: string;
}

Alpine.data("pharmacy", () => ({
  searchTerm: "",
  showResults: false,
  enableButton(): boolean {
    return this.searchTerm !== "";
  },
  search() {
    if (this.enableButton() === true) {
      this.showResults = false;
      this.$dispatch("pharmacy-search");
      // eslint-disable-next-line no-promise-executor-return
      this.showResults = true;
    }
  },
}));

Alpine.data("pharmacyModal", () => ({
  pharmacy: {
    name: null,
    address: null,
    id: null,
  },
}));

interface Props {
  toggleModal: (index: number) => void;
}

type Args = Array<Pharmacy>;

const pharmacyListData: HydratedComponent<Args, Props> = (pharmacyList) => ({
  ...({} as ComponentBase),
  toggleModal(index: number) {
    this.$dispatch("populate-modal", { pharmacy: pharmacyList[index] });
    this.$dispatch("show-modal", "pharmacy");
  },
});

Alpine.data("pharmacyListData", hydrateComponent(pharmacyListData));
