import * as htmx from "htmx.org";
import * as Sentry from "@sentry/browser";
import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";
import intersect from "@alpinejs/intersect";
import mask from "@alpinejs/mask";
import morph from "@alpinejs/morph";
import Alpine from "alpinejs";
import debounce from "lodash.debounce";
import type { HTMXResponseErrorEvent } from "./htmx";

type AlpinePlugin = (alpine: typeof Alpine) => void;

Alpine.data("htmxMiddleware", () => ({
  destroy() {
    if (this.hxObserver instanceof MutationObserver) {
      this.hxObserver.disconnect();
      delete this.hxObserver;
    }
  },
  init() {
    // HACK: Set up a MutationObserver to process HTMX attributes any time an element is
    // added to the document. This is a potential performance bottleneck!
    const hxObserver = new MutationObserver(
      debounce(() => htmx.process(document.body), 0, { leading: true })
    );
    // NOTE: Monitoring attributes may be overkill and can be disabled IIF HTMX attributes
    // are never added dynamically. For maximum compatibility leave it enabled.
    hxObserver.observe(document, { attributes: true, childList: true, subtree: true });
    this.hxObserver = hxObserver;
  },
  onResponseError(evt: HTMXResponseErrorEvent) {
    Sentry.captureException(new Error(evt.detail.error));
  },
  onReloadBody() {
    window.location.reload();
  },
}));

window.Alpine = Alpine;
Alpine.plugin(focus as AlpinePlugin);
Alpine.plugin(intersect as AlpinePlugin);
Alpine.plugin(collapse as AlpinePlugin);
Alpine.plugin(mask as AlpinePlugin);
Alpine.plugin(morph as AlpinePlugin);
Alpine.start();
