import * as Sentry from "@sentry/browser";
import wompWomp from "./wompWomp";

Sentry.init({
  autoSessionTracking: window.$SENTRY_ENVIRONMENT !== "local",
  beforeSend(event) {
    // NOTE: comment the following line to allow testing Sentry events locally.
    if (window.$SENTRY_ENVIRONMENT === "local") return null;
    if (event.level === "error" || event.level === "fatal") wompWomp(event.event_id);
    return event;
  },
  dsn: window.$SENTRY_DSN,
  environment: window.$SENTRY_ENVIRONMENT,
  integrations: [new Sentry.BrowserTracing()],
  release: window.$SENTRY_RELEASE,
  tracesSampleRate: window.$SENTRY_ENVIRONMENT === "prod" ? 0.01 : 1.0,
});
