// This utility module dynamically sets a CSS variable so that mobile viewport heights can
// be computed correctly.
// Refernce: https://www.markusantonwolf.com/blog/solution-to-the-mobile-viewport-height-issue-with-tailwind-css/

function setViewHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

setViewHeight();
window.addEventListener("resize", setViewHeight);
