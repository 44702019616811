import {
  loadStripe,
  Stripe,
  StripeCardNumberElement,
  StripeCardNumberElementOptions,
  StripeElement,
  StripeElements,
  StripeError,
} from "@stripe/stripe-js";
import AlpineInstance from "alpinejs";
import { ComponentBase, HydratedComponent } from "..";
import { hydrateComponent } from "../util";

interface Args {
  stripePublicKey: string;
}

interface Props {
  errorMessage: string;
  clientSecret?: string | null;
  cardElements: Record<string, StripeElement>;
  disableInputs: boolean;
  error: boolean;
  loading: boolean;
  paymentMethodId: string;
  stripe?: Stripe | null;
  stripePublicKey: string;
  elements?: StripeElements;
  promoCode: null; // These are needed to make the card_input happy
  activePrice: null; // These are needed to make the card_input happy
  init: () => Promise<void>;
  updatePaymentMethod: () => Promise<void>;
  updateClientSecret: () => void;
  clearInputs: () => void;
  toggleInputs: () => void;
  reInitForm: () => void;
  hideModal: () => void;
  showError: (message?: string) => void;
}

type UpdatePaymentResponse = {
  result: string;
  message?: string;
};

const updatePayment: HydratedComponent<Args, Props> = ({ stripePublicKey }) => ({
  ...({} as ComponentBase),
  cardElements: {},
  errorMessage: "",
  disableInputs: false,
  error: false,
  loading: false,
  paymentMethodId: "",
  stripePublicKey,
  promoCode: null,
  activePrice: null,
  async init() {
    this.stripe = await loadStripe(this.stripePublicKey);
    if (!this.stripe) throw new Error("failed to load stripe API");
    this.elements = this.stripe?.elements();
    const cardNumOptions: StripeCardNumberElementOptions = {
      showIcon: true,
    };
    this.cardElements = {
      "#card-number": this.elements?.create("cardNumber", cardNumOptions),
      "#card-expiry": this.elements?.create("cardExpiry"),
      "#card-cvc": this.elements?.create("cardCvc"),
    };

    Object.entries(this.cardElements).forEach(([id, element]) => {
      element.mount(id);
      // @ts-expect-error // element.on() has conflicting signatures
      element.on("change", ({ error }: { error: StripeError }) => {
        const displayError = document.querySelector(`${id}-error`) as HTMLParagraphElement;
        const inputWrapper = document.querySelector(id) as HTMLElement;

        if (error) {
          displayError.textContent = error.message ? error.message : "";
          inputWrapper.classList.add("error");
        } else {
          displayError.textContent = "";
          inputWrapper.classList.remove("error");
        }
      });
    });
  },
  toggleInputs() {
    this.disableInputs = !this.disableInputs;
    (document.getElementById("zip") as HTMLInputElement).disabled = this.disableInputs;
    Object.values(this.cardElements).forEach((element) => {
      element.update({ disabled: this.disableInputs });
    });
  },
  clearInputs() {
    (document.getElementById("zip") as HTMLInputElement).value = "";
    Object.values(this.cardElements).forEach((element) => {
      element.update({ value: "" });
    });
  },
  hideModal() {
    this.$dispatch("dismiss", "update");
    this.$dispatch("show-modal", { id: "schedule-confirm" });
  },
  showError(message?: string) {
    this.error = true;
    if (message) {
      this.errorMessage = message;
    } else {
      this.errorMessage = "Failed to update card.";
    }
  },
  updateClientSecret() {
    const clientSecretDiv = document.getElementById("client-secret");
    this.clientSecret = clientSecretDiv?.dataset.secret;
  },
  reInitForm() {
    this.updateClientSecret();
    if (this.loading) {
      this.loading = false;
      this.toggleInputs();
    }
  },
  async updatePaymentMethod() {
    this.error = false;
    this.loading = !this.loading;
    this.toggleInputs();
    if (!this.stripe || !this.elements) {
      throw new Error("Stripe was not initialized");
    }
    this.updateClientSecret();
    if (!this.clientSecret) {
      throw new Error("Could not load client-secret");
    }

    const { setupIntent, error } = await this.stripe.confirmCardSetup(this.clientSecret, {
      payment_method: {
        card: this.cardElements["#card-number"] as StripeCardNumberElement,
        billing_details: {
          name: (document.querySelector("#cardholder-name") as HTMLInputElement).value,
          address: {
            postal_code: (document.querySelector("#zip") as HTMLInputElement).value,
          },
        },
      },
    });
    if (error) {
      this.toggleInputs();
      this.loading = false;
      this.showError(error.message);
      // alert(result.error.message);
    } else {
      const formData = new FormData();
      const csrfMiddlewareToken = (
        document.querySelector("input[name='csrfmiddlewaretoken']") as HTMLInputElement
      ).value;
      formData.append("csrfmiddlewaretoken", csrfMiddlewareToken);
      formData.append("pm_id", (setupIntent.payment_method as string).toString());

      const updateRes = await fetch("/payment/update-pm/", {
        method: "POST",
        body: formData,
      });

      if (updateRes.ok) {
        this.$dispatch("reload-card-info");
        this.loading = false;
        this.hideModal();
        this.clearInputs();
        this.toggleInputs();
      } else {
        if (updateRes.headers.get("content-type")?.includes("application/json")) {
          const data = (await updateRes.json()) as UpdatePaymentResponse;
          this.showError(data.message);
        } else {
          this.showError(updateRes.statusText);
        }
        this.$dispatch("reload-secret");
      }
    }
  },
});

AlpineInstance.data("updatePayment", hydrateComponent(updatePayment));
