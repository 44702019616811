import AWC, { Attribute, Attributes, html } from "../alpineWebComponent";

const template = html`
  <div @click.self="$refs.label.click()" :class="classes" x-modelable="modelValue">
    <div class="flex h-7 items-center">
      <input
        :id="id"
        :aria-describedby="descriptionId"
        :name="name"
        :type="type"
        :class="inputClass"
        :disabled="disabled"
        :required="required"
        :value="value"
        x-model="modelValue"
      />
    </div>
    <label :for="id" :class="labelClass" :disabled="disabled" x-ref="label">
      <slot name="label"></slot>
    </label>
    <p :id="descriptionId" :class="descriptionClass">
      <span class="sr-only"><slot name="sr-label"></slot></span>
      <slot name="description"></slot>
    </p>
    <p class="text-sys-error" x-text="message"></p>
  </div>
`;

const attrs = {
  checked: Attribute.Boolean(),
  disabled: Attribute.Boolean(),
  required: Attribute.Boolean(),
  "input-id": Attribute.String(),
  name: Attribute.String(),
  radio: Attribute.Boolean(),
  bordered: Attribute.Boolean(),
  "x-class": Attribute.String(),
  "description-class": Attribute.String(),
  value: Attribute.String(),
  errors: Attribute.JSON<string[]>(),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attributes<typeof attrs> {
  classes(): string;
  type: () => string;
  id: () => string | undefined;
  inputClass: () => string;
  descriptionId: () => string;
  descriptionClass: () => string;
  labelClass: () => string;
  modelValue: boolean | string | undefined;
}

export class Checkbox extends AWC<State, Attrs>("x-checkbox", attrs, template) {
  data() {
    return {
      type: () => (this.state.radio ? "radio" : "checkbox"),
      classes: () =>
        `relative flex items-center ${this.state["x-class"] || ""}${
          this.state.bordered
            ? "px-2 py-1 border-2 rounded-lg has-[:checked]:border-s-orange/75"
            : ""
        }`,
      id: () =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.state["input-id"] || this.state.$id(this.state.name!, this.state.value),
      inputClass: () => (this.state.radio ? "input-radio rounded-full" : "input-checkbox"),
      descriptionId: () =>
        [this.state["input-id"] || this.state.name, "-description"].join(""),
      descriptionClass: () => `text-s-gray ${this.state["description-class"] || ""}`,
      labelClass: () => {
        let cls = "ml-3 grow app-text-body-lg select-none";
        cls += this.state.disabled
          ? " text-ext-slate cursor-not-allowed disabled"
          : " text-p-navy";
        return cls;
      },
      message: () => {
        if (this.state.errors && this.state.errors.length) {
          return this.state.errors.join("\n");
        }
        return "";
      },
      modelValue: this.hasAttribute("checked") ? true : undefined,
    };
  }
}

Checkbox.define();

export default Checkbox;
