import AlpineInstance from "alpinejs";
import { AnalyticsEvent, track } from "../services/analytics";

AlpineInstance.data("checkoutModal", () => ({
  state: "loading",
  failureMessage: "",
  updateState(state: string) {
    if (state === "failure") {
      this.dismissible = true;
    }
    if (state === "success") {
      track(AnalyticsEvent.PAYMENT_METHOD_CONFIRMED);
    }
    this.state = state;
  },
  updateFailureMessage(message: string) {
    this.failureMessage = message;
  },
}));
