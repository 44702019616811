import AWC, { Attribute, Attributes, html } from "../alpineWebComponent";

const template = html`
  <div
    class="block rounded-lg border border-ext-light-gray divide-y divide-ext-light-gray mb-6"
  >
    <div
      class="flex cursor-pointer p-4 sm:pl-8 sm:pr-6 gap-4 sm:gap-6"
      @click="toggleAccordion()"
    >
      <h5 is="headline" class="grow">
        <slot name="name"> </slot>
      </h5>
      <div class="flex place-items-center w-6 h-6 relative overflow-hidden shrink-0">
        <div class="absolute w-10 h-10 -left-2">
          <div
            class="w-10 h-10 transition duration-200 ease-in-out"
            :class="accordionToggleClass()"
          >
            <x-icon name="caret_down" x-class="w-10 h-10"></x-icon>
          </div>
        </div>
      </div>
    </div>
    <div x-show="expanded" x-collapse.duration.425ms>
      <div class="block sm:flex p-6 sm:p-8 sm:gap-6 relative">
        <div class="sm:w-1/2">
          <div class="sm:max-w-md">
            <h5 is="headline" class="mb-4 !text-s-orange !font-bold">
              <slot name="title"> </slot>
            </h5>
            <p is="md">
              <slot name="copy"> </slot>
            </p>
          </div>
        </div>
        <div class="sm:w-1/2 relative mt-6 sm:mt-0">
          <template x-if="$data['img-src']">
            <img
              :alt="$data['img-alt']"
              class="mx-auto h-full w-full max-w-xs
            sm:max-w-none sm:absolute object-contain"
              :src="$data['img-src']"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
`;

const attrs = {
  "img-alt": Attribute.String(""),
  "img-src": Attribute.String(""),
  expanded: Attribute.Boolean(),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  init: () => void;
  accordionToggleClass: () => Record<string, boolean>;
  toggleAccordion: () => void;
  expanded: boolean;
}

export class TreatmentGoal extends AWC<State, Attrs>("x-treatment-goal", attrs, template) {
  data(): Omit<State, keyof Attrs> {
    return {
      init: () => {
        this.state.expanded = this.state.expanded || false;
      },
      accordionToggleClass: () => ({ "rotate-180": this.state.expanded }),
      toggleAccordion: () => {
        this.state.expanded = !this.state.expanded;
      },
    };
  }
}

TreatmentGoal.define();

export default TreatmentGoal;
