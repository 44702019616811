/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { AlpineWebComponent, Attribute, Attributes, html } from "../alpineWebComponent";

const template = html`
  <div :class="classes">
    <x-modal :id="targetModalId()" dismissible>
      <slot name="modal-content"></slot>
    </x-modal>
    <div class="flex-1">
      <template x-if="title">
        <h5 class="mb-3 !font-bold" x-text="title" is="headline"></h5>
      </template>
    </div>
    <template x-if="$data['button-label']">
      <div>
        <button x-bind="buttonAttrs">
          <span x-text="$data['button-label']" class="leading-none"></span>
        </button>
      </div>
    </template>
  </div>
`;

const attrs = {
  title: Attribute.String(""),
  "button-label": Attribute.String(),
  "take-action-id": Attribute.String("take-action"),
  "form-url": Attribute.String(),
  "x-class": Attribute.String(),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  classes: () => string;
  buttonAttrs: () => Record<string, unknown>;
  targetModalId: () => string;
}

export class TakeActionCard extends AlpineWebComponent<State, Attrs>(
  "x-take-action-card",
  attrs,
  template
) {
  data() {
    return {
      classes: () => {
        let cls =
          "border-solid border border-l-8 border-ext-light-gray rounded-r-lg border-l-p-teal p-6 shadow-md block sm:flex sm:flex-col";
        if (this.state["x-class"]) cls += ` ${this.state["x-class"]}`;
        return cls;
      },
      buttonAttrs: () => ({
        id: `take-action-${this.state["take-action-id"]}`,
        href: "#",
        "@click.prevent": "$dispatch('show-modal', targetModalId())",
        is: "x-button",
        class:
          "x-button !py-2 sm:!px-10 !h-auto !bg-p-teal hover:!bg-[#238B8B] focus:!ring-[#238B8B]",
      }),
      targetModalId: () => `modal-${this.state["take-action-id"]}`,
    };
  }
}

TakeActionCard.define();

export default TakeActionCard;
