import AWC, { html, Attribute, Attributes } from "../alpineWebComponent";

const template = html`
  <div class="relative" x-id="['menu-button', 'menu-panel']">
    <a x-bind="buttonAttrs">
      <slot name="button"></slot>
    </a>
    <div x-bind="panelAttrs">
      <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
`;

const attrs = {
  "button-class": Attribute.String(),
  "panel-class": Attribute.String(),
  open: Attribute.Boolean(),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  buttonAttrs: () => Record<string, unknown>;
  close: (focusAfter?: boolean) => void;
  panelAttrs: () => Record<string, unknown>;
  toggle: () => void;
}

export class Menu extends AWC<State, Attrs>("x-menu", attrs, template) {
  data(): Omit<State, keyof Attrs> {
    return {
      buttonAttrs: () => ({
        "@click.prevent.stop": this.state.toggle,
        "aria-controls": this.state.$id("panel"),
        "aria-expanded": this.state.open,
        id: this.state.$id("menu-button"),
        "x-ref": "button",
        class: `menu-button ${this.state["button-class"] || ""}`,
        href: "#",
      }),
      close: (focusAfter) => {
        this.state.open = false;
        if (focusAfter) (this.state.$refs.button as HTMLButtonElement).focus();
      },
      panelAttrs: () => ({
        "@click.outside": () => this.state.close(true),
        "@focusin.window": (ev: FocusEvent) => {
          /* istanbul ignore next */
          if (!this.state.$refs.panel.contains(ev.target as Node)) this.state.close();
        },
        "@keydown.escape.prevent.stop.window": () => this.state.close(true),
        "aria-labelledby": this.state.$id("menu-button"),
        "aria-orientation": "vertical",
        "x-ref": "panel",
        "x-show": () => this.state.open,
        "x-transition:enter-end": "opacity-100 translate-y-0",
        "x-transition:enter-start": "opacity-0 translate-y-1",
        "x-transition:enter": "transition ease-out duration-200",
        "x-transition:leave-end": "opacity-0 translate-y-1",
        "x-transition:leave-start": "opacity-100 translate-y-0",
        "x-transition:leave": "transition ease-in duration-150",
        class: `menu-panel ${
          this.state["panel-class"] ||
          "absolute left-1/2 z-20 mt-3 px-2 sm:px-0 max-w-xs w-screen transform -translate-x-full"
        }`,
        id: this.state.$id("menu-panel"),
        role: "menu",
        style: "display: none;",
        tabindex: "-1",
      }),
      toggle: () => {
        this.state.open = !this.state.open;
      },
    };
  }
}

Menu.define();

export default Menu;
