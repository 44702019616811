import AWC, { Attribute, html } from "../alpineWebComponent";

const template = html`
  <div>
    <template x-if="logo">
      <img :src="logo" alt="logo" class="logo" />
    </template>
    <template x-if="title">
      <h1 class="title" x-text="title"></h1>
    </template>
    <slot></slot>
    <slot name="caption">
      <template x-if="caption">
        <p class="caption" x-html="caption"></p>
      </template>
    </slot>
  </div>
`;

const attrs = {
  caption: Attribute.String(),
  logo: Attribute.String(),
  title: Attribute.String(),
};

export class Dialog extends AWC("x-dialog", attrs, template) {}

Dialog.define();

export default Dialog;
