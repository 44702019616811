import Icon from "../icon";

export class Spinner extends Icon {
  data() {
    return {
      ...super.data(),
      color: "inherit",
      name: "loading",

      classes: () =>
        `animate-spin w-4 h-4 inline-block text-${this.state.color || "inherit"} ${
          this.state["x-class"] || ""
        }`,
    };
  }
}

Spinner.define("x-spinner");

export default Spinner;
