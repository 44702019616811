import { AlpineWebComponent, Attribute, Attributes, html } from "../alpineWebComponent";

const template = html`
  <span :aria-label="name" :class="classes" x-effect="render">
    <span><!-- dynamically replaced with svg element --></span>
  </span>
`;

const attrs = {
  color: Attribute.String("p-navy"),
  name: Attribute.String(),
  "x-class": Attribute.String(),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  classes(): string;
  render(): void;
}

export class Icon extends AlpineWebComponent<State, Attrs>("x-icon", attrs, template) {
  data() {
    return {
      classes: () =>
        `inline-block text-${this.state.color || "p-navy"} ${this.state["x-class"] || ""}`,

      render: () => {
        const child = this.children[0];
        if (!child) return;
        if (!this.state.name) return;
        const svg = document.getElementById(
          `ico-${this.state.name}`
        ) as HTMLTemplateElement;
        if (!child || !svg) return;
        child.replaceWith(svg.content.cloneNode(true));
      },
    };
  }
}

Icon.define();

export default Icon;
