import Alpine from "alpinejs";
import AWC, { Attribute, Attributes, html } from "../alpineWebComponent";

const template = html`
  <div @click="toggle" :class="classes">
    <span class="flex flex-grow flex-col mr-4">
      <span class="app-text-body-lg text-p-navy select-none" :id="id + '-label'">
        <slot name="label"></slot>
      </span>
      <span class="app-text-body-md text-s-gray" :id="id + '-description'">
        <slot name="description"></slot>
      </span>
    </span>
    <button
      :aria-checked="checked"
      :checked="checked"
      :class="btnClass"
      :disabled="disabled"
      :aria-describedby="id + '-description'"
      :aria-labelledby="id + '-label'"
      class="btn-toggle"
      role="switch"
      type="button"
    >
      <span :class="actuatorClass" aria-hidden="true" class="btn-toggle-actuator"></span>
    </button>
  </div>
`;

const attrs = {
  checked: Attribute.Boolean(),
  disabled: Attribute.Boolean(),
  id: Attribute.String(),
  "x-class": Attribute.String(),
};

interface State extends Attributes<typeof attrs> {
  actuatorClass: () => Record<string, boolean>;
  btnClass: () => Record<string, boolean>;
  classes(): string;
  toggleDisabled: () => void;
  toggle: () => boolean;
}

type Attrs = Attributes<typeof attrs>;

export class Toggle extends AWC<State, Attrs>("x-toggle", attrs, template) {
  private previousId?: string;

  connectedCallback() {
    super.connectedCallback();
    Alpine.effect(() => {
      const { id } = this.state;
      if (id) {
        window.addEventListener(`${id}-toggle-disabled`, this.state.toggleDisabled);
      }
      if (this.previousId) {
        window.removeEventListener(
          `${this.previousId}-toggle-disabled`,
          this.state.toggleDisabled
        );
      }
      this.previousId = id;
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    const { id } = this.state;
    if (id) {
      window.removeEventListener(`${id}-toggle-disabled`, this.state.toggleDisabled);
    }
  }

  data() {
    return {
      actuatorClass: () => ({
        "btn-toggle-actuator": true,
        "translate-x-0": !this.state.checked,
        "translate-x-5": this.state.checked,
      }),
      btnClass: () => {
        const c = this.state.checked;
        const d = this.state.disabled;
        return {
          "bg-ext-slate": d && !c,
          "bg-p-navy": !d && !c,
          "bg-s-orange": !d && c,
          "bg-s-orange/75": d && c,
          "btn-toggle": true,
          "focus:ring-p-navy": !c,
          "focus:ring-s-orange": c,
        };
      },
      classes: () => `flex items-center justify-between ${this.state["x-class"] || ""}`,
      toggleDisabled: () => {
        this.state.disabled = !this.state.disabled;
      },
      toggle: () => {
        if (!this.state.disabled) this.state.checked = !this.state.checked;
        return this.state.checked;
      },
    };
  }
}

Toggle.define();

export default Toggle;
