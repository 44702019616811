import * as Sentry from "@sentry/browser";
import AlpineInstance from "alpinejs";
import AWC, { Attribute, Attributes, TargetedEvent, html } from "../alpineWebComponent";

export interface ModalStore {
  bodyScrollTop: number;
  next: number;
  stack: number[];
  closeAll(): void;
  init(): void;
  isTop(id: number): boolean;
  onError(msg: string): void;
  pop(id: number): number;
  push(): number;
  zIndex(id: number): number;
}

// FIXME[ZST-1410]: Relocate to dedicated store module
const Alpine = AlpineInstance as typeof AlpineInstance & {
  store(name: "modal"): ModalStore;
  store(name: "modal", value: ModalStore): void;
};

// In desktop web browsers overflowing content can cause scrollbars to appear and
// disappear. When visible they typically add about 15px of special "padding" to the
// containing element, and hiding the overflow causes a re-layout as that special padding
// disappears. To avoid this re-layout it is necessary to compute the scrollbar width and
// add that as padding to the element before setting overflow to hidden.
// This measurement is typically 15px but it can vary. It should be measured immediately
// prior to disabling the scrollbars; if there is no overflow at the moment the measurement
// will be zero. This function will also return zero for most mobile browsers.
// https://davidwalsh.name/detect-scrollbar-width
function getScrollbarWidth() {
  const el = document.createElement("div");
  el.style.height = "100%";
  el.style.overflow = "scroll";
  el.style.position = "absolute";
  el.style.top = "-999999px";
  el.style.width = "100%";
  document.body.appendChild(el);
  const w = el.offsetWidth - el.clientWidth;
  document.body.removeChild(el);
  return w;
}

const scrollContainer = document.scrollingElement || document.documentElement;

// A global store is used to keep track of the global modal stacking order (based on
// order of presentation) and setting the `inert` attribute to children of the body
// element while a blocking modal is open.
Alpine.store("modal", {
  bodyScrollTop: -1,
  next: 1,
  stack: [],
  init() {
    // This effect ensures that non-modal children of the body element have the inert
    // attribute added while a blocking modal is open.
    Alpine.effect(() => {
      // Read from `this.stack` so this function runs on any change to the modal stack, even
      // though this function is mostly concerned about querying from the DOM directly. The
      // specific element being accessed or the function being called here are not relevant.
      this.stack.indexOf(0);
      const z = document.querySelectorAll(".modal-dialog.open.modal").length;
      const children = Array.from(document.body.children).filter(
        (el) =>
          el.tagName.toLowerCase() !== "x-modal" &&
          el.tagName.toLowerCase() !== "template" &&
          !el.classList.contains("modal-dialog")
      );
      Alpine.mutateDom(() => {
        if (z > 0 && this.bodyScrollTop < 0) {
          this.bodyScrollTop = scrollContainer.scrollTop;
          document.body.classList.add("no-scroll");
          document.body.style.top = `-${this.bodyScrollTop}px`;
          document.body.style.paddingRight = `${getScrollbarWidth()}px`;
          children.forEach((el) => el.setAttribute("inert", ""));
        } else if (z <= 0 && this.bodyScrollTop >= 0) {
          document.body.style.paddingRight = "";
          document.body.style.top = "";
          document.body.classList.remove("no-scroll");
          scrollContainer.scrollTop = this.bodyScrollTop;
          this.bodyScrollTop = -1;
          children.forEach((el) => el.removeAttribute("inert"));
        }
      });
    });
  },
  closeAll() {
    document
      .querySelectorAll("x-modal")
      .forEach((m) => (m as unknown as { close(): void }).close());
  },
  onError(msg) {
    Sentry.captureException(new Error(msg));
  },
  isTop(id) {
    return this.zIndex(id) === this.stack.length - 1;
  },
  /** Removes a modal from the stack; a valid modal ID must be provided. */
  pop(id) {
    const i = this.stack.indexOf(id);
    if (i === -1) {
      this.onError(
        "Invariant Violation: attempted to pop a modal more than once or an invalid ID was provided"
      );
      this.next = 1;
      this.stack = [];
      this.closeAll();
    } else {
      this.stack.splice(i, 1);
    }
    return this.stack.length;
  },
  /**
   * Adds a new modal to the top of the stack and returns its ID; this ID must be provided
   * to subsequent `zIndex` and `pop` calls.
   */
  push() {
    let next = this.next++;
    /* istanbul ignore if */
    if (next >= Number.MAX_SAFE_INTEGER) {
      // eslint-disable-next-line no-console
      console.warn("Congratulations. You win a prize.");
      this.closeAll();
      this.next = 1;
      this.stack = [];
      next = this.next;
    }
    this.stack.push(next);
    return next;
  },
  /**
   * Determines the relative z-index of the given modal in the stack, or -1 if the ID
   * is not valid.
   */
  zIndex(id) {
    return this.stack.indexOf(id);
  },
} as ModalStore);

const attrs = {
  /**
   * Controls whether the user can dismiss the modal. If dismissable, the escape key
   * will close the modal and an X button will appear in the top right corner. The `dismiss`
   * event, `.close()` function, and `open` attribute are the only ways to close a
   * non-dismissible modal.
   */
  dismissible: Attribute.Boolean(),
  /**
   * Tracks whether the modal is currently open. This attribute may be set to control
   * visibility but it will not be modal (blocking). To add a backdrop and block the UI the
   * `show-modal` event or `.showModal` function must be used instead.
   */
  open: Attribute.Boolean(),
  /** Adds an optional title to the top of the modal dialog. */
  title: Attribute.String(),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  /** Opens the dialog in modal (blocking) mode: covers the window with a backdrop and
   * prevents interaction with other elements except the topmost modal. */
  blocking: boolean;
  classes: () => Record<string, boolean>;
  dialog: () => Record<string, unknown>;
  init: () => void;
  isTop: () => boolean;
  modal: () => Record<string, unknown>;
  /** A monotonic identifier for the modal that is used for z-indexing. */
  modalId: number;
  onClickOutside: (ev: MouseEvent) => void;
  onDismiss: (ev: TargetedEvent<{ value: string }>) => void;
  onEscape: (ev: KeyboardEvent) => void;
  onShow: (ev: TargetedEvent) => void;
  onShowModal: (ev: TargetedEvent) => void;
  styles: () => Partial<CSSStyleDeclaration>;
  value: string;
}

const template = html`
  <div x-bind="modal">
    <template x-teleport="body">
      <div x-bind="dialog">
        <div class="modal-backdrop"></div>
        <div class="modal-overlay">
          <div class="modal-container">
            <div class="modal-panel" @mousedown.outside.stop="onClickOutside">
              <div class="modal-header">
                <slot name="title">
                  <h5 is="headline" x-show="!!title" x-text="title"></h5>
                  <div class="grow" x-show="!title"></div>
                </slot>
                <button
                  @click="onDismiss"
                  class="cursor-pointer w-fit"
                  x-show="dismissible"
                  data-testid="modal-close"
                >
                  <x-icon name="x" class="w-5"></x-icon>
                </button>
              </div>
              <div :id="$id('modal-content')" class="modal-content" autofocus>
                <slot></slot>
              </div>
              <div class="modal-footer">
                <slot name="footer"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
`;

const wasOpen = Symbol("wasOpen");

/**
 * A modal dialog, implemented as per the HTML5 specification for dialog elements.
 *
 * This component is inspired by the `HTMLDialogElement` class and attempts to imitate its
 * native functionality as much as possible. It does not extend that class due to lack of
 * support from WebKit.
 *
 * Opening and closing the modal can be achieved either by dispatching a `show-modal`
 * or `dismiss` event, or by calling the `.showModal()` or `.close()` functions on the DOM
 * node directly.
 *
 * The escape key is automatically handled and will emit a `cancel` event followed by a
 * `close` event. The `cancel` event itself is cancelable in case the modal needs to stay
 * open, and will automatically cancel itself if the modal is not dismissible.
 *
 * All global event listeners are "targeted" events; a string or object with an `id`
 * property can be passed to target a component by its ID. If no target is provided all
 * dialog elements on the page will act on the event. This works particularly well with the
 * [`$id` magic](https://alpinejs.dev/magics/id).
 *
 * ```html
 * <span x-data x-id="['foo-modal']">
 *   <x-modal :id="$id('foo-modal')">Foo</x-modal>
 *   <button x-on:click="$dispatch('show-modal', $id('foo-modal'))">Open</button>
 * </span>
 * ```
 *
 * See more: https://html.spec.whatwg.org/multipage/interactive-elements.html#the-dialog-element
 *
 * Global event listeners:
 *
 *   - **dismiss**: Calls `close` on the native dialog element with an optional
 *     `returnValue`. This is called `dismiss` to prevent conflict with the `close`
 *     event that is emitted _after_ the dialog is closed.
 *
 *   - **show**: Calls `show` on the modal to present the dialog in a non-modal manner
 *     (no backdrop, non-blocking). This is most suitable for creating "toast" style
 *     notifications and use of this mode should be uncommon. In desktop screen sizes the
 *     non-blocking modal will be attached to the bottom of the display just like blocking
 *     modals in mobile.
 *
 *   - **show-modal**: Calls `showModal` on the native dialog element to present
 *     the dialog in a modal (blocking) manner. All elements outside of the dialog element
 *     are blocked from user interaction until the modal is closed.
 */
export class Modal extends AWC<State, Attrs>("x-modal", attrs, template) {
  // FIXME[ZST-1410]: this belongs in the base AWC class
  $store = Alpine.store;

  close(returnValue?: string) {
    if (returnValue !== undefined) this.returnValue = returnValue;
    this.state.open = false;
  }

  data(): Omit<State, keyof Attrs> {
    return {
      classes: () => ({
        "modal-dialog": true,
        modal: this.state.blocking,
        open: this.state.open,
      }),
      dialog: () => ({
        ":aria-label": "title",
        ":aria-modal": "blocking",
        ":class": "classes",
        ":style": "styles",
        "@keydown.escape.window": "onEscape",
        "aria-details": this.state.$id("modal-content"),
        role: "dialog",
        "x-cloak": true,
        "x-ref": "dialog",
      }),
      init: () => {
        Alpine.effect(() => {
          const { modalId, open } = this.state;
          if (!open && this[wasOpen]) {
            this.$store("modal").pop(modalId);
            this.dispatchEvent(new CustomEvent("close", { bubbles: true }));
          }
          this[wasOpen] = open;
        });
      },
      blocking: false,
      isTop: () => {
        const { modalId, open } = this.state;
        const isTop = this.$store("modal").isTop(modalId);
        return !!open && isTop;
      },
      modal: () => ({
        "@dismiss.window": this.state.onDismiss,
        "@dismiss.stop": this.state.onDismiss,
        "@show-modal.window": this.state.onShowModal,
        "@show.window": this.state.onShow,
        "x-cloak": true,
        "x-id": JSON.stringify(["modal-content"]),
        "x-modelable": "value",
      }),
      modalId: 0,
      onClickOutside: (ev) => {
        const { blocking, dismissible } = this.state;
        const top = this.state.isTop();
        if (dismissible && blocking && top) {
          ev.stopImmediatePropagation();
          this.close();
        }
      },
      onDismiss: (ev) => {
        if (!this.$isTargetedByEvent(ev)) return;
        ev.stopPropagation();
        if (!ev.detail || typeof ev.detail === "string") {
          this.close();
        } else {
          this.close(ev.detail.value);
        }
      },
      onEscape: (ev) => {
        if (!this.state.dismissible || !this.state.isTop()) return;
        const cancel = new CustomEvent("cancel", { bubbles: true, cancelable: true });
        if (this.dispatchEvent(cancel)) {
          ev.stopImmediatePropagation();
          this.close();
        }
      },
      onShow: (ev) => {
        if (!this.$isTargetedByEvent(ev)) return;
        ev.stopPropagation();
        this.show();
      },
      onShowModal: (ev) => {
        if (!this.$isTargetedByEvent(ev)) return;
        ev.stopPropagation();
        this.showModal();
      },
      styles: () => ({
        zIndex: `${this.$store("modal").zIndex(this.state.modalId) + 100}`,
      }),
      value: "",
    };
  }

  disconnectedCallback() {
    this.close();
    super.disconnectedCallback();
  }

  show(): void {
    this.state.blocking = false;
    const modalStore = this.$store("modal");
    if (this.state.open) {
      modalStore.pop(this.state.modalId);
    } else {
      this.state.open = true;
    }
    this.state.modalId = modalStore.push();
  }

  showModal(): void {
    this.show();
    this.state.blocking = true;
  }

  get returnValue() {
    return this.state.value;
  }

  set returnValue(value) {
    this.state.value = value;
  }

  [wasOpen] = false;
}

Modal.define();

export default Modal;
