import Alpine from "alpinejs";
import { ComponentBase, HydratedComponent } from "../..";
import { hydrateComponent } from "../../util";

interface Args {
  dismissible: boolean;
  dismissViaBackdrop: boolean;
  showModal: boolean;
}

interface Props {
  dismissible: boolean;
  dismissViaBackdrop: boolean;
  showModal: boolean;
  showTitle: boolean;
  dismiss(): void;
  backdropDismiss(): void;
  show(): void;
  toggleModal(): void;
}

const modal: HydratedComponent<Args, Props> = ({
  dismissible,
  dismissViaBackdrop,
  showModal,
}) => ({
  ...({} as ComponentBase),
  dismissible,
  dismissViaBackdrop,
  showModal,
  showTitle: true,
  dismiss() {
    if (this.dismissible) this.showModal = false;
  },
  backdropDismiss() {
    if (this.dismissible && this.dismissViaBackdrop) this.showModal = false;
  },
  show() {
    this.showModal = true;
  },
  toggleModal() {
    if (!this.showModal) {
      this.showModal = !this.showModal;
    } else if (this.dismissible) {
      this.showModal = !this.showModal;
    }
  },
});

Alpine.data("modal", hydrateComponent(modal));
