import * as Sentry from "@sentry/browser";

export default function wompWomp(evId?: string) {
  // eslint-disable-next-line no-console
  console.log(`
Our team has been notified of the error that just occurred on the page and is working to
get it fixed.

If you are in communication with member support about this issue, please mention the
following event ID: ${evId || Sentry.lastEventId() || "UNKNOWN"}
`);
}
