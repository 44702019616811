/* eslint-disable camelcase */
import * as Sentry from "@sentry/browser";
import Alpine from "alpinejs";
import posthog from "posthog-js";
import {
  HTMXHistoryRestoreEvent,
  HTMXPushedIntoHistoryEvent,
  HTMXReplacedInHistoryEvent,
} from "../htmx";
import { IterableApi } from "./iterable";
import { RindAnalytics } from "./rindAnalytics";

export enum AnalyticsEvent {
  BEGIN_INTAKE = "BEGIN_INTAKE",
  SUBMIT_WELCOME_EMAIL = "SUBMIT_WELCOME_EMAIL",
  REQUEST_MEDICAL_RECORDS = "REQUEST_MEDICAL_RECORDS",
  CHAT_OPEN = "CHAT_OPEN",
  PAYMENT_METHOD_CONFIRMED = "PAYMENT_METHOD_CONFIRMED",
  INTRO_SCHEDULED = "INTRO_SCHEDULED",
  SUBSCRIBED = "SUBSCRIBED",
  TYPEFORM_READY = "TYPEFORM_READY",
  INTAKE_SURVEY_READY = "INTAKE_SURVEY_READY",
  MESSAGE_SENT_FROM_PATIENT = "MESSAGE_SENT_FROM_PATIENT",
  TO_DO_READY = "TO_DO_READY",
  BEGIN_TO_DO = "BEGIN_TO_DO",
}

export interface AnalyticsUpdate {
  lead_pk?: string;
  user_pk?: string;
}

type UpdateAnalyticsArguments = {
  lead_pk?: string;
  user_pk?: string;
};

type PendoInitializeArguments = {
  visitor: { id: string };
  account: { id: string };
  disableGuides?: boolean;
};

/** https://developers.google.com/tag-platform/gtagjs/reference */
export enum GoogleTagCommand {
  CONFIG = "config",
  GET = "get",
  SET = "set",
  EVENT = "event",
  CONSENT = "consent",
}

/** https://developers.google.com/tag-platform/gtagjs/reference */
export interface GoogleTagAPI {
  (command: "config", targetId: string, additionalConfig?: Record<string, unknown>): void;
  (
    command: "consent",
    consentArg?: "default" | "update",
    consentParams?: {
      ad_storage: "allowed" | "denied";
      analytics_storage: "allowed" | "denied";
      wait_for_update: number;
    }
  ): void;
  (command: "event", eventName: string, eventParams?: Record<string, unknown>): void;
  (
    command: "get",
    target: string,
    fieldName: string,
    callback: (field: string) => void
  ): void;
  (command: "js", date: Date): void;
  (command: "set", parameters: Record<string, string | Record<string, string>>): void;
}

declare global {
  interface Window {
    readonly dataLayer?: unknown[];
    readonly pendo?: {
      initialize: (args: PendoInitializeArguments) => void;
      track: (event: string, data?: Record<string, unknown>) => void;
    };
    readonly gtag?: GoogleTagAPI;
    /**
     * A global object to use for tracking analytics events that abstracts the concept of
     * sending events to any number of analytics APIs/providers. Example:
     *
     * ```html
     * <button @click="$$A.track($$EV.BEGIN_INTAKE)">
     * ```
     */
    $$A: {
      /**
       * Track an analytics event.
       *
       * An optional value may be provided for events with additional data, which can be
       * any JSON-serializable object.
       */
      track: (event: AnalyticsEvent, data?: Record<string, unknown>) => void;
    };
    /**
     * A global dictionary of analytics events as a convenient alternative to importing the
     * AnalyticsEvent enum, or in contexts where import is not avaialble (templated HTML).
     */
    $$EV: typeof AnalyticsEvent;
    /**
     * Will either record the Iterable client API key or be undefined if the Iterable
     * integration has been disabled.
     */
    $$ITERABLE_KEY: string | undefined;
    /**
     * May contain a token to use for authenticating client-side events to Iterable. This
     * is only known if there is already an active session and the Lead has been
     * identified, otherwise a JWT must be fetched from the backend. This will be set to
     * undefined ("consumed") after the first time the Iterable web SDK requests a token.
     */
    $$ITERABLE_TOKEN: string | undefined;
    /**
     * Will either record the current session's lead public key or be undefined if the user
     * is still anonymous.
     */
    $$LEAD_PK: string | undefined;
    /**
     * Will either record the Pendo API key or be undefined if the Pendo integration has
     * been disabled.
     */
    $$PENDO_KEY: string | undefined;
    /**
     * Records the currently logged-in user's public key.
     */
    $$USER_PK: string | undefined;
  }
}

const iterable = new IterableApi();
const rindAnalytics = new RindAnalytics();

export function track(event: AnalyticsEvent, data: Record<string, unknown> = {}) {
  const eventName = AnalyticsEvent[event].toLowerCase();
  // eslint-disable-next-line no-console
  if (window.$$DEBUG) console.debug(`Analytics event: ${eventName}`);

  if (window.gtag) {
    window.gtag("event", eventName, data);
  }

  if (window.$$PENDO_KEY && window.$$USER_PK) {
    window.pendo?.track(eventName, data);
  }

  if (window.$$POSTHOG_TOKEN) {
    posthog.capture(eventName, data);
  }

  iterable.track(eventName, data).catch(Sentry.captureException);

  rindAnalytics.track(eventName).catch(Sentry.captureException);
}

function trackPageView(url: string) {
  if (window.gtag) {
    window.gtag("event", "page_view", {
      page_location: url,
      page_title: document.title,
    });
  }
  if (window.$$POSTHOG_TOKEN) {
    posthog.capture("$pageview", { $current_url: url });
  }
}

function updateUserProperties() {
  const lead_pk = window.$$LEAD_PK;
  const user_pk = window.$$USER_PK;

  // GA4
  if (window.gtag) {
    if (lead_pk) {
      // NOTE: In GA4 a "user" is what Zest calls a "lead".
      window.gtag("set", {
        lead_pk,
        user_id: lead_pk,
        user_properties: { lead_pk, user_id: lead_pk },
      });
    }

    if (user_pk) {
      window.gtag("set", { user_pk, user_properties: { user_pk } });
    }
  }

  // Iterable
  if (lead_pk) {
    iterable.login(lead_pk).catch(Sentry.captureException);
  }

  // Posthog
  if (window.$$POSTHOG_TOKEN && lead_pk) {
    posthog.identify(lead_pk, { user_pk });
  }

  // Pendo
  if (window.pendo && lead_pk && user_pk) {
    window.pendo.initialize({
      visitor: { id: lead_pk },
      account: { id: "Zest" },
      disableGuides: true,
    });
  }
}

function initAnalytics() {
  window.$$A = { track };
  window.$$EV = AnalyticsEvent;

  if (window.gtag && window.$$GTAG_DESTINATION_ID) {
    window.gtag("js", new Date());
    window.gtag("config", window.$$GTAG_DESTINATION_ID);
  }

  if (window.$$POSTHOG_TOKEN) {
    posthog.init(window.$$POSTHOG_TOKEN, { api_host: "https://us.posthog.com" });
  }

  updateUserProperties();
}

initAnalytics();

Alpine.data("analyticsMiddleware", () => ({
  onLogout() {
    posthog.reset();
  },

  onUpdate(ev: CustomEvent<UpdateAnalyticsArguments>) {
    const { lead_pk, user_pk } = ev.detail;

    if (lead_pk) window.$$LEAD_PK = lead_pk;
    if (user_pk) window.$$USER_PK = user_pk;

    updateUserProperties();
  },
}));

document.body.addEventListener("htmx:pushedIntoHistory", (ev) => {
  trackPageView((ev as HTMXPushedIntoHistoryEvent).detail.path);
});
document.body.addEventListener("htmx:historyRestore", (ev) => {
  trackPageView((ev as HTMXHistoryRestoreEvent).detail.path);
});
document.body.addEventListener("htmx:replacedInHistory", (ev) => {
  trackPageView((ev as HTMXReplacedInHistoryEvent).detail.path);
});
