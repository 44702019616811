/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { AlpineWebComponent, Attribute, Attributes, html } from "../alpineWebComponent";

const template = html`
  <div class="relative text-ext-slate">
    <hr :class="classes" />
    <template x-if="label">
      <div
        x-text="label"
        class="absolute h-6 -top-3 left-0 bg-p-white px-8 ml-8 app-text-h5 font-bold"
      ></div>
    </template>
  </div>
`;

const attrs = {
  label: Attribute.String(""),
  "x-class": Attribute.String(),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  classes(): string;
}

export class Ruler extends AlpineWebComponent<State, Attrs>("x-ruler", attrs, template) {
  data() {
    return {
      classes: () => {
        let cls = "border-ext-slate my-32";
        if (this.state["x-class"]) cls += ` ${this.state["x-class"]}`;
        return cls;
      },
    };
  }
}

Ruler.define();

export default Ruler;
