import AlpineInstance from "alpinejs";
import { Component, ComponentBase } from "..";

interface Props {
  open: boolean;
  hasToggled: boolean;
  sending: boolean;
  message: string;
  shiftPressed: boolean;
  chatInterval: ReturnType<typeof setInterval> | undefined;
  loadMessage: () => void;
  scrollChat: (force?: boolean) => void;
  sendMessage: () => void;
  startPolling: () => void;
}

const chat: Component<Props> = () => ({
  ...({} as ComponentBase),
  open: false,
  hasToggled: false,
  sending: false,
  message: "",
  shiftPressed: false,
  chatInterval: undefined,
  destroy() {
    clearInterval(this.chatInterval);
  },
  init() {
    this.loadMessage();
    const { chatinput } = this.$refs;
    if (chatinput) chatinput.textContent = this.message;
    (this.$refs.chatinput as HTMLDivElement).textContent = this.message;
    document.body.addEventListener("chat-sent", () => {
      (document.getElementById("id_message") as HTMLInputElement).value = "";
      (document.getElementById("id_message") as HTMLInputElement).disabled = false;
      if (chatinput) (chatinput as HTMLDivElement).innerText = "";
      this.message = "";
      this.scrollChat(true);
      this.sending = !this.sending;
      this.$dispatch("refresh-chat");
      window.$$A.track(window.$$EV.MESSAGE_SENT_FROM_PATIENT);
    });
    this.startPolling();
  },
  scrollChat(force = false) {
    if (!this.hasToggled || force) {
      this.hasToggled = true;
      const chatBody = document.querySelector(".chat-body-inner");
      if (chatBody) {
        chatBody.scrollTop = chatBody.scrollHeight;
      }
    }
  },
  startPolling() {
    clearInterval(this.chatInterval);
    this.chatInterval = setInterval(() => {
      this.$dispatch("poll-chat");
    }, 1000);
  },
  loadMessage() {
    let message = "";
    if (localStorage.getItem("default_message")) {
      message = localStorage.getItem("default_message") as string;
      localStorage.removeItem("default_message");
    }
    this.message = message;
  },
  sendMessage() {
    const msgInput = document.getElementById("id_message") as HTMLInputElement;
    if (msgInput.value) {
      this.$dispatch("send-chat");
      this.sending = !this.sending;
      msgInput.disabled = true;
    }
  },
});

AlpineInstance.data("chat", chat);
