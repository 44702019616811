import { AlpineWebComponent, Attribute, Attributes, html } from "../alpineWebComponent";

const template = html`<time x-text="formattedDate"></time>`;

const attrs = {
  calendar: Attribute.String("gregory"),
  "date-style": Attribute.String(),
  day: Attribute.String(),
  "day-period": Attribute.String(),
  era: Attribute.String(),
  "format-matcher": Attribute.String(),
  "fractional-second-digits": Attribute.Integer(),
  hour: Attribute.String(),
  "hour-12": Attribute.Boolean(),
  "hour-cycle": Attribute.String(),
  minute: Attribute.String(),
  month: Attribute.String(),
  "numbering-system": Attribute.String(),
  second: Attribute.String(),
  "time-style": Attribute.String(),
  "time-zone-name": Attribute.String(),
  "time-zone": Attribute.String(),
  value: Attribute.String(), // ISO-8601
  weekday: Attribute.String(),
  year: Attribute.String(),
};

type Attrs = Attributes<typeof attrs>;

interface State extends Attrs {
  formattedDate: () => string;
}

export class DateTime extends AlpineWebComponent<State, Attrs>(
  "x-datetime",
  attrs,
  template
) {
  data() {
    return {
      formattedDate: () => {
        const date = this.state.value ? new Date(this.state.value) : Date.now();
        const locale = undefined; // Do we want to specify en-US?
        const options: Intl.DateTimeFormatOptions = {
          calendar: this.state.calendar,
        };
        // dateStyle and timeStyle can be used with each other, but not with other
        // date-time component options (e.g. weekday, hour, month, etc.).
        if (this.state["date-style"] || this.state["time-style"]) {
          if (this.state["date-style"])
            options.dateStyle = this.state["date-style"] as typeof options.dateStyle;
          if (this.state["time-style"])
            options.timeStyle = this.state["time-style"] as typeof options.timeStyle;
        } else {
          if (this.state.day) options.day = this.state.day as typeof options.day;
          if (this.state["day-period"])
            options.dayPeriod = this.state["day-period"] as typeof options.dayPeriod;
          if (this.state.era) options.era = this.state.era as typeof options.era;
          if (this.state["format-matcher"])
            options.formatMatcher = this.state[
              "format-matcher"
            ] as typeof options.formatMatcher;
          if (this.state["fractional-second-digits"])
            options.fractionalSecondDigits = this.state[
              "fractional-second-digits"
            ] as typeof options.fractionalSecondDigits;
          if (this.state.hour) options.hour = this.state.hour as typeof options.hour;
          if (this.state.minute)
            options.minute = this.state.minute as typeof options.minute;
          if (this.state.second)
            options.second = this.state.second as typeof options.second;
          if (this.state.month) options.month = this.state.month as typeof options.month;
          if (this.state.weekday)
            options.weekday = this.state.weekday as typeof options.weekday;
          if (this.state.year) options.year = this.state.year as typeof options.year;
          if (this.state["time-zone-name"])
            options.timeZoneName = this.state[
              "time-zone-name"
            ] as typeof options.timeZoneName;
        }
        if (this.state["hour-12"])
          options.hour12 = this.state["hour-12"] as typeof options.hour12;
        if (this.state["hour-cycle"])
          options.hourCycle = this.state["hour-cycle"] as typeof options.hourCycle;
        if (this.state["numbering-system"])
          options.numberingSystem = this.state[
            "numbering-system"
          ] as typeof options.numberingSystem;
        if (this.state["time-zone"])
          options.timeZone = this.state["time-zone"] as typeof options.timeZone;
        return Intl.DateTimeFormat(locale, options).format(date);
      },
    };
  }
}

DateTime.define();

export default DateTime;
