import AWC, { html } from "../alpineWebComponent";

const template = html`
  <div>
    <slot name="top"></slot>
    <div class="divider"></div>
    <slot name="bottom"></slot>
  </div>
`;

export class Split extends AWC("x-split", {}, template) {}

Split.define();

export default Split;
